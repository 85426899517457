<script setup lang="ts">
import OzBasePopover, { OzBasePopoverColors, type Anchor } from '@@/library/v4/components/OzBasePopover.vue'
import type { ComputePositionConfig } from '@floating-ui/vue'

withDefaults(
  defineProps<{
    /* ---------------------- */
    /* FUNCTIONALITY          */
    /* ---------------------- */
    /**
     * An anchor. Either an element or a pair of coordinates (x, y).
     */
    anchor: Anchor
    /**
     * Popover configuration. Uses Floating UI.
     * Reference: https://floating-ui.com/
     */
    config: Partial<ComputePositionConfig>

    /* ---------------------- */
    /* GENERAL APPEARANCE     */
    /* ---------------------- */
    /**
     * Dark mode
     */
    darkMode?: boolean | 'auto'

    /* ---------------------- */
    /* BOX APPEARANCE         */
    /* ---------------------- */
    /**
     * The popover body radius. Uses OzBox.
     */
    colorScheme?: ColorScheme
  }>(),
  {
    darkMode: 'auto',
    colorScheme: ColorScheme.Base,
  },
)
</script>

<script lang="ts">
enum ColorScheme {
  Base = 'Base',
}

export default {}
export { ColorScheme as OzTooltipColorScheme }
</script>

<template>
  <OzBasePopover
    :class="[
      // Shape
      'px-3 py-2',

      // Functionality
      'pointer-events-none',

      // Appearance
      'text-body-small',
      'shadow-elevation-1',

      colorScheme === ColorScheme.Base && [
        {
          'text-light-text-100 ': darkMode === false,
          'text-dark-text-100': darkMode === true,
          'text-light-text-100 dark:text-dark-text-100': darkMode === 'auto',
        },
        {
          'bg-common-ui-d70 ': darkMode === false,
          'bg-common-ui-l86': darkMode === true,
          'bg-common-ui-d70 dark:bg-common-ui-l86': darkMode === 'auto',
        },
        'backdrop-blur-6',
      ],
    ]"
    role="tooltip"
    :anchor="anchor"
    :config="config"
    :dark-mode="darkMode"
    :radius="8"
    :color="OzBasePopoverColors.None"
  >
    <slot></slot>
  </OzBasePopover>
</template>
